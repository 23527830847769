import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import WJHome from "./pages/WJHome";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="wjhome" element={<WJHome />} />
    </Routes>
  );
};

export default App;

