import { useTitle } from "react-use";
import styled from "styled-components";

const LinksContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
`;

const Grid = styled.div`
  display: flex-grid;
  flex-wrap: wrap;
  max-width: 1280px;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  box-shadow: 2px 2px 4px #888888;
  cursor: pointer;
  border-radius: 5px;
  width: 150px;
  height: 150px;
`;

const CardText = styled.div`
  padding-top: 10px;
  text-align: center;
`;

const UnstyledLink = styled.a`
  text-decoration: none;
  height: 100%;
  display: block;
`;

const WJHome: React.FC = () => {
  useTitle("WJ Home");
  return (
    <LinksContainer>
      <h3>Useful Links:</h3>
      <div>
        <Grid>
          <UnstyledLink href="https://github.com/TomBuurmans/Wellbeing-Journal">
            <Card>
              <img
                src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
                width={100}
                alt="GitHub"
              />
              <CardText>GitHub Repo</CardText>
            </Card>
          </UnstyledLink>
          <UnstyledLink href="https://trello.com/b/e3xKTGhd/development">
            <Card>
              <img
                src="https://pbs.twimg.com/profile_images/1361722806694785027/UY7DlO0a.png"
                width={100}
                alt="Trello"
                style={{ borderRadius: "5px" }}
              />
              <CardText>Trello Development Board</CardText>
            </Card>
          </UnstyledLink>
          <UnstyledLink href="https://www.figma.com/files/project/44868948/WJ-project">
            <Card>
              <img
                src="https://avatars.slack-edge.com/2021-02-11/1743993243810_70c06a3b9201ba9e34c1_512.png"
                width={100}
                alt="GitHub"
              />
              <CardText>Figma</CardText>
            </Card>
          </UnstyledLink>
          <UnstyledLink href="https://console.firebase.google.com/u/0/project/wellbeing-journal/overview">
            <Card>
              <img
                src="https://www.gstatic.com/devrel-devsite/prod/v583c167abdd1a21bfbd770256d119796fdffc0b7177f088bca68fc6b48429661/firebase/images/touchicon-180.png"
                width={100}
                alt="Firebase"
              />
              <CardText>Firebase Console</CardText>
            </Card>
          </UnstyledLink>
          <UnstyledLink href="https://dashboard.virgilsecurity.com/apps/7f5150b4991d4e399abe8a26028f3996/">
            <Card>
              <img
                src="https://pbs.twimg.com/profile_images/625561148557930497/g1uCFUqt_400x400.png"
                width={100}
                alt="Virgil Security"
                style={{ borderRadius: "5px" }}
              />
              <CardText>Virgil Security Console</CardText>
            </Card>
          </UnstyledLink>
          <UnstyledLink href="https://drive.google.com/drive/u/0/folders/1sibU4ZwVDBoxRxWf83YZ4J2yt6Rx-p2f">
            <Card>
              <img
                src="https://storage.googleapis.com/gweb-uniblog-publish-prod/images/Google_Drive.max-1100x1100.png"
                width={100}
                alt="Google Drive"
              />
              <CardText>Shared Google Drive Folder</CardText>
            </Card>
          </UnstyledLink>
        </Grid>
      </div>
    </LinksContainer>
  );
};

export default WJHome;
